<template>
  <div class="relative">
    <p class="relative__title">Related {{ type }}</p>

    <ul class="relative-list">
      <li class="relative-list__item" v-for="item of mockData" :key="item.label" @click="toAlbumsInfo(item.id)">
        <img class="relative-list__item__img" src="@/assets/album/3.jpeg" />
        <p class="relative-list__item__label">{{ item.label }}</p>
        <p class="relative-list__item__id">{{ item.id }}</p>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'RelativedAblums',
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  setup() {
    const mockData = [
      {
        label: 'Nordic Drama1',
        id: 'NORD122',
      },
      {
        label: 'Nordic Drama2',
        id: 'NORD122',
      },
      {
        label: 'Nordic Drama3',
        id: 'NORD122',
      },
      {
        label: 'Nordic Drama4',
        id: 'NORD122',
      },
    ];

    const router = useRouter();

    const toAlbumsInfo = (id) => {
      router.push({
        name: 'explore-albums-info',
        query: {
          id,
          title: `標題${id}`,
        },
      });
    };

    return {
      mockData,
      toAlbumsInfo,
    };
  },
});
</script>
<style lang="scss" scoped>
.relative {
  display: block;
  margin-top: 40px;

  &__title {
    @include font-style($c-black, 16, bold, 0.8px, 18px);
  }
}

.relative-list {
  @include flex();
  flex-wrap: wrap;
  margin: 12px -5px 0;

  &__item {
    flex: 50%;
    @include padding(0 5px);
    margin-top: 20px;
    cursor: pointer;

    &:first-child,
    &:nth-child(2) {
      margin-top: 0px;
    }

    &__img {
      width: 100%;
      border-radius: 5px;
    }

    &__label {
      @include font-style($c-black, 14, bold, 0.7px, 18px);
    }

    &__id {
      @include font-style($c-assist6, 14, 400, 0.7px, 18px);
    }
  }
}

@media screen and (min-width: $portrait) {
  .relative-list {
    &__item {
      flex: 25%;

      margin-top: 0px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .relative-list {
    &__item {
      flex: 50%;
      margin-top: 20px;

      &:first-child,
      &:nth-child(2) {
        margin-top: 0px;
      }
    }
  }
}
</style>
