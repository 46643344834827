<template>
  <div class="c-playlists-albums-info">
    <p class="curr-music-title">GRAVITY - The Infinite Universe</p>
    <p class="curr-music-desc">
      Every fast paced action movie needs some Epic Strings With Beats, so here’s the perfect playlist to help you with
      your selection.
    </p>
    <p class="curr-music-info">
      <template v-if="route.name === 'explore-albums-info'">
        <span class="curr-music-info__label">ID</span>
        <span class="curr-music-info__id">GTP252</span>
        <span class="curr-music-info__label">Labels</span>
        <span class="curr-music-info__labels">atmosphere</span>
      </template>
    </p>
    <div class="divide-line"></div>
    <div class="music">
      <div class="music__music-player">
        <music-function :functionList="['add','download', 'star', 'share']" showPlay />
      </div>
      <div class="music__more-info">
        <img class="music__more-info__icon" src="@/assets/mobile/icon_menu_info.svg" @click="openModal" />
      </div>
      <div class="music__other">
        <p class="music__other__detail">
          <span class="music__other__detail__label">Category</span>
          <span class="music__other__detail__category">Emotional, Happy, Positive, Dance, Electronica</span>
        </p>
        <p class="music__other__detail" v-if="route.name === 'explore-albums-info'">
          <span class="music__other__detail__label">Release date</span>
          <span class="music__other__detail__release-date">04 Oct 2021</span>
        </p>
      </div>
    </div>
    <div class="tracks">
      <p class="tracks__text">10 Tracks</p>
    </div>

    <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false">
      <template #header>
        <div class="header-wrap">
          <cover-name-code-normal :data="headerMockData" />
        </div>
      </template>
      <model-base-layout title="分享曲目" decoLine cusHeader>
        <track-info :data="trackInfoMockData" padding />
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { NModal } from 'naive-ui';
import MusicFunction from '@/components/Global/MusicPlayer/MusicFunction.vue';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
import TrackInfo from '@/components/MyMusic/MyMusicList/ListDetail/MusicDetail/TrackInfo.vue';
import CoverNameCodeNormal from '@/components/Global/MusicPlayer/Fragment/CoverNameCodeNormal.vue';

export default defineComponent({
  name: 'PlaylistsAlbumsInfo',
  components: {
    MusicFunction,
    NModal,
    ModelBaseLayout,
    CoverNameCodeNormal,
    TrackInfo,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  setup() {
    const modelStatus = ref(false);
    const route = useRoute();

    const openModal = () => {
      modelStatus.value = true;
    };

    const headerMockData = {
      title: 'GRAVITY - The Infinite Universe',
      desc: 'GTP252',
    };

    const trackInfoMockData = [
      {
        title: 'Album',
        data: ['Beyond The Clouds'],
      },
      {
        title: 'Composer',
        data: ['Abbie Lathe [ PRS ] ', 'Al Lethbridge [ PRS ]'],
      },
      {
        title: 'Publisher',
        data: ['BBC Production Music [ PRS ]'],
      },
      {
        title: 'Labels',
        data: ['BBC Production Music'],
      },
    ];

    return {
      route,
      modelStatus,
      openModal,
      trackInfoMockData,
      headerMockData,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-playlists-albums-info {
}

.curr-music-title {
  margin-top: 20px;
  @include font-style($c-black, 18, bold, 0.9, 24px);
}

.curr-music-desc {
  display: none;
}

.curr-music-info {
  margin-top: 16px;

  &__label {
    @include font-style($c-assist3, 16, normal, 0, 24px);
  }

  &__id {
    @include font-style($c-assist6, 16, bold, 0, 24px);
    margin: 0 17px 0 10px;
  }

  &__labels {
    @include font-style($c-assist6, 16, normal, 0, 24px);
    text-decoration: underline;
    margin-left: 10px;
  }
}

.divide-line {
  width: 100%;
  height: 1px;
  background-color: $c-assist7;
  margin: 16px 0;
}

.music {
  @include flex(space-between);

  &__detail {
    &__music-player {
    }

    &__more-info {
      &__icon {
        cursor: pointer;
      }
    }
  }

  &__other {
    display: none;
  }
}

.tracks {
  margin-top: 24px;

  &__text {
    @include font-style($c-assist3, 14, 600, 0.7px, 18px);
  }
}

.header-wrap {
  margin-left: 20px;
}

@media screen and (min-width: $tablet) {
  .c-playlists-albums-info {
  }

  .curr-music-title {
    @include font-style($c-black, 35, bold, 0, 47px);
  }

  .curr-music-desc {
    display: block;
    margin-top: 16px;
    @include font-style($c-assist6, 16, normal, 0, 24px);
  }

  .curr-music-info {
    margin-top: 45px;

    &__label {
      @include font-style($c-assist3, 16, normal, 0, 24px);
    }

    &__id {
      @include font-style($c-assist6, 16, bold, 0, 24px);
      margin: 0 17px 0 10px;
    }

    &__labels {
      @include font-style($c-assist6, 16, normal, 0, 24px);
      text-decoration: underline;
      margin-left: 10px;
    }
  }

  .divide-line {
    width: 100%;
    height: 1px;
    background-color: $c-assist7;
    margin: 16px 0;
  }

  .music {
    @include flex(space-between);

    &__music-player {
    }

    &__more-info {
      display: none;
    }

    &__other {
      margin-left: 30px;
      display: block;

      &__detail {
        &__label {
          @include font-style($c-assist3, 16, normal, 0, 24px);
        }

        &__category {
          @include font-style($c-assist6, 14, normal, 0, 24px);
          margin: 0 20px 0 10px;
        }

        &__release-date {
          @include font-style($c-assist6, 14, normal, 0, 24px);
          margin-left: 10px;
        }
      }
    }
  }

  .tracks {
    margin-top: 38px;

    &__text {
      @include font-style($c-assist3, 14, 600, 0.7px, 18px);
    }
  }
}
</style>
